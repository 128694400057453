import React from 'react';
import './bandcamp.css';

function BandcampPlayer() {
    return (
        <div className='bandcamp-player'>
              <iframe title="youtube" className='player-frame'
              src="https://bandcamp.com/EmbeddedPlayer/album=3793209403/size=large/bgcol=222222/linkcol=0687f5/artwork=none/transparent=true/" seamless>
                <a href="http://soulvanity.bandcamp.com/album/end-of-time">End of time by SoulVanity</a>
              </iframe>
        </div>
    );
}
export default BandcampPlayer;
