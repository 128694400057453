import React from 'react';
import EndOfTimeCover from '../images/endOfTimeCover.jpg';
import InnerUnrestCover from '../images/innerUnrestCover.jpg';
import DemoCover from '../images/demoCover.jpg';
import './album.css';



function Album() {
    return (
        <div className='albumSection'>
        {/* <h1>Albums</h1> */}
            <div id='album2' className='albumGroup coverOn'>
                <img src={EndOfTimeCover} className="albumCover" alt="Album cover"/>
                <h4>End Of Time</h4>
                <div className='albumDetails'>
                    <ul className='detailsList'>
                        <li>Released &nbsp;:&nbsp; December 2019</li>
                        <li>Engineered &nbsp;:&nbsp; George Stournaras</li>
                        <li>Artwork &nbsp;:&nbsp; Jim Evgenidis</li>
                    </ul>
                    <div class='listenToSection'>
                        Listen on
                        <a class='listenLinks'
                        href="https://www.youtube.com/playlist?list=PLqHAZsiPTAlaG3E7L0tjxoS8YfRejSTfq"
                        target='_blank'>&nbsp;YouTube</a>
                        <br />
                    </div>
                </div>
            </div>
            <div id='album1' className='albumGroup coverOff'>
                <img src={InnerUnrestCover} className="albumCover" alt="Album cover"/>
                <h4>Inner Unrest</h4>
                <div className='albumDetails'>
                    <ul className='detailsList'>
                        <li>Released &nbsp;:&nbsp; May 2016</li>
                        <li>Engineered &nbsp;:&nbsp; George Stournaras</li>
                        <li>Artwork &nbsp;:&nbsp; Nick Mass</li>
                    </ul>
                    <div class='listenToSection'>
                        Listen on
                        <a class='listenLinks'
                        href="https://www.youtube.com/playlist?list=PLqHAZsiPTAlbVQ916IXKJf-U8CcUICAV4"
                        target='_blank'>&nbsp;YouTube</a>
                        <br />
                    </div>
                </div>
            </div>
            <div id='album0' className='albumGroup coverOff'>
                <img src={DemoCover} className="albumCover" alt="Album cover"/>
                <h4>Demo</h4>
                <div className='albumDetails'>
                    <ul className='detailsList'>
                        <li>Released &nbsp;:&nbsp; Nov 2013</li>
                        <li>Engineered &nbsp;:&nbsp; Menelaos Giannakou</li>
                        <li>Artwork &nbsp;:&nbsp; Jordan Helljester</li>
                    </ul>
                    <div class='listenToSection'>
                        Listen on
                        <a class='listenLinks'
                        href='https://www.youtube.com/playlist?list=PLqHAZsiPTAlZUwEWCXdmPJM3geig-I03_'
                        target='_blank'>&nbsp;YouTube</a>
                        <br />
                    </div>
                </div>
            </div>
            <div>

            </div>
        </div>
    );
}
export default Album;