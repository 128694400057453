import React from 'react';
import './liveshows.css';
import Lives from './eventdata.js';
import EventCard from './event.jsx';

function createEvent(Lives) {
    return (
        <EventCard
            id={Lives.id}
            key={Lives.id}
            fbLink={Lives.fbLink}
            dateString={Lives.dateString}
            venue={Lives.venue}
            city={Lives.city}
            country={Lives.country}
            lineUp={Lives.lineUp}
            />
    );
}

function LiveShows() {
    return (
        <div className='liveShows'>
            <div className='liveshows-frame'>
                <h1>UPCOMING SHOWS</h1>
                <ul>
                    {/* <li className='list-item'> */}
                        <h4>
                            Nothing scheduled.
                        </h4>
                        <h4>
                            Contact us to book a show!
                        </h4>
                    {/* </li> */}
                </ul>
                <h1>PAST EVENTS</h1>
                <ul>
                    {Lives.map(createEvent)}
                </ul>
                <br />
            </div>
        </div>
    );
}
export default LiveShows;