import React from 'react';
import SoulvanityLogo from '../images/soulvanity_logo_2019_01.png';
import './header.css';

function Header() {
    return (
        <div className="headerSection">
            <br/>
            <div className='Soulvanity-logo-div'>
                <a href='https://www.soulvanity.gr' className="logo-link" target="_self" rel="noreferrer">
                    <img src={SoulvanityLogo} className="Soulvanity-logo" alt="Soulvanity logo"/>        
                </a>
            </div>
        </div>

    );

}
export default Header;