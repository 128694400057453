import React from 'react';
import './youtube.css';

function YouTubeVideo() {
    return (
        <div className='youtube-player'>
                <iframe className='youtube-frame' width="560" height="315" src="https://www.youtube.com/embed/XiJiHpWAE3I?si=tAo9_KoqMhfnGc2b"
                title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                <p className='youtube-par'>Soulvanity "Break The Chains" lyric video</p>
        </div>
    );
}
export default YouTubeVideo;
