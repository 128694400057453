const Lives = [
    {
        id:19,
        fbLink:"https://fb.me/e/a3dYUbiHt",
        dateString:"Saturday 8 June 2024",
        venue:"Eightball Club",
        city:"Thessaloniki",
        country:"GR",
        lineUp:"SYNTHETIC - SOULVANITY - HAND OF FATE"
    },
    {
        id:18,
        fbLink:"https://fb.me/e/7i8skfxK6",
        dateString:"Friday 24 January 2020",
        venue:"Eightball Club",
        city:"Thessaloniki",
        country:"GR",
        lineUp:"SOULVANITY - THE STRANGE STR - METEORIGHTS"
    },
    {
        id:17,
        fbLink:"https://lastdaydeaf.com/last-day-deaf-media-partner-circus-caravan-soulvanity-echo-cables-11-may-blue-barrel-thessaloniki-greece/",
        dateString:"Friday 11 May 2018",
        venue:"Blue Barrel",
        city:"Thessaloniki",
        country:"GR",
        lineUp:"CIRCUS CARAVAN - SOULVANITY - ECHO CABLES"
    },
    {
        id:16,
        fbLink:"https://fb.me/e/a6NoWHthX",
        dateString:"Sunday 15 April 2018",
        venue:"Eightball Club",
        city:"Thessaloniki",
        country:"GR",
        lineUp:"EMBRACE THE PARADOX - END CYCLE - SEASONS AC - TREASON AS RELIEF - SOULVANITY"
    },
    {
        id:15,
        fbLink:"https://www.facebook.com/share/p/kUiuuADa762bDqEt/",
        dateString:"Friday 2 February 2018",
        venue:"Blue Barrel",
        city:"Thessaloniki",
        country:"GR",
        lineUp:"PROJECT THEORY - SCARS OF TEARS - SOULVANITY"
    },
    {
        id:14,
        fbLink:"https://www.facebook.com/share/p/vToBaPbUMPbcqhWB/",
        dateString:"Saturday 27 January 2018",
        venue:"Take Five Live Stage",
        city:"Kilkis",
        country:"GR",
        lineUp:"JAW BONES - SOULVANITY"
    },
    {
        id:13,
        fbLink:"https://www.facebook.com/share/kWrjaPkHPtEjawWr/",
        dateString:"Saturday 21 October 2017",
        venue:"Silver Dollar",
        city:"Thessaloniki",
        country:"GR",
        lineUp:"SCANDAL - NU/ANCE - CHIPPER - SOULVANITY - CRIMSON VEIN"
    },
    {
        id:12,
        fbLink:"https://fb.me/e/6SKo3prew",
        dateString:"Saturday 29 April 2017",
        venue:"Silver Dollar",
        city:"Thessaloniki",
        country:"GR",
        lineUp:"MADRAKE - SOULVANITY"
    },
    {
        id:11,
        fbLink:"https://www.facebook.com/avoideternal/photos/t.100048928881737/1761868367422636/?type=3",
        dateString:"Saturday 17 December 2016",
        venue:"Silver Dollar",
        city:"Thessaloniki",
        country:"GR",
        lineUp:"INHALE EXHALE METAL FEST"
    },
    {
        id:10,
        fbLink:"https://fb.me/e/4mdfcIO3d",
        dateString:"Saturday 6 November 2016",
        venue:"Eightball Club",
        city:"Thessaloniki",
        country:"GR",
        lineUp:"HEADSPIN FESTIVAL VOL. 6"
    },
    {
        id:9,
        fbLink:"https://www.facebook.com/soulvanitytheband/photos/pb.100048928881737.-2207520000/1057139917668604/?type=3",
        dateString:"Friday 27 May 2016",
        venue:"Eightball Club",
        city:"Thessaloniki",
        country:"GR",
        lineUp:"NIGHTMARE - BREAKTHROUGH - FYRECROSS - SOULVANITY"
    },
    {
        id:8,
        fbLink:"https://fb.me/e/1X9qjWJUF",
        dateString:"Saturday 14 May 2016",
        venue:"Silver Dollar",
        city:"Thessaloniki",
        country:"GR",
        lineUp:"SOULVANITY - WILD SIDE"
    },
    {
        id:7,
        fbLink:"https://fb.me/e/3IuLBckb8",
        dateString:"Sunday 17 January 2016",
        venue:"Eightball Club",
        city:"Thessaloniki",
        country:"GR",
        lineUp:"SORROWS PATH - LACHRYMOSE - SOULVANITY - BEYOND THE EXISTENCE"
    },
    {
        id:6,
        fbLink:"https://fb.me/e/2c9AFRLLo",
        dateString:"Friday 9 January 2015",
        venue:"Silver Dollar",
        city:"Thessaloniki",
        country:"GR",
        lineUp:"SOULVANITY - LEFTOVER BULLETS"
    },
    {
        id:5,
        fbLink:"https://fb.me/e/2bJeaO0eh",
        dateString:"Sunday 28 September 2014",
        venue:"Eightball Club",
        city:"Thessaloniki",
        country:"GR",
        lineUp:"CHRONOMANCY - DIARY OF SECRETS - SENSE OF FEAR - SOULVANITY"
    },
    {
        id:4,
        fbLink:"https://fb.me/e/2pziD7uuh",
        dateString:"Friday 11 April 2014",
        venue:"Silver Dollar",
        city:"Thessaloniki",
        country:"GR",
        lineUp:"SCARS OF TEARS - SOULVANITY"
    },
    {
        id:3,
        fbLink:"https://fb.me/e/1CXMXeMR1",
        dateString:"Thursday 30 January 2014",
        venue:"Silver Dollar",
        city:"Thessaloniki",
        country:"GR",
        lineUp:"SOULVANITY"
    },
    {
        id:2,
        fbLink:"https://fb.me/e/3DK9onWEP",
        dateString:"Monday 23 December 2013",
        venue:"Eightball Club",
        city:"Thessaloniki",
        country:"GR",
        lineUp:"XMAS FEST VOL. 4"
    },
    {
        id:1,
        fbLink:"https://fb.me/e/42idAEBiX",
        dateString:"Friday 1 February 2013",
        venue:"Eightball Club",
        city:"Thessaloniki",
        country:"GR",
        lineUp:"BEWIZED - STARVE THE HYDRA - SOULVANITY - ΓΟΥΡΟΥΝΙΑ ΣΤΟ ΔΙΑΣΤΗΜΑ"
    }
];

export default Lives;