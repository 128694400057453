import React from 'react';

function eventCard(props) {
    return  (
        <li className='list-item'>
            <h4>
                <a  className='eventLink' href={props.fbLink} rel='noreferrer' target='_blank'>
                    <span className='dateString'>
                        {props.dateString},{' '}
                    </span>
                    <span className='venue'>
                        {props.venue},{' '}
                    </span>
                    <span className='city'>
                    {props.city},{' '}
                    </span>
                    <span className='country'>
                        {props.country}<br />
                    </span>
                    <span className='lineUp'>
                        {props.lineUp}
                    </span>
                </a>
            </h4>
        </li>
    );
}
export default eventCard;